const extractUrlFragment = (): string | null => {
  const hash = window.location.hash;

  if (hash) {
    const fragment = hash.substring(1);
    window.history.replaceState(null, '', window.location.pathname + window.location.search);
    return fragment;
  }

  return null;
};

window.__EXTRACTED_URL_FRAGMENT__ = extractUrlFragment();
